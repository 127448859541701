import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountInfo, LocalStorageUtil } from 'src/app/util/local-storage-util';
import { UiChangePasswordComponent } from '../ui-change-password/ui-change-password.component';
import { LoginService } from 'src/app/services/login.service';

type MenuItem = {
  label: string;
  icon: string;
  link?: string;
  submenu: { label: string; link: string }[];
};

@Component({
  selector: 'app-ui-main-menu',
  templateUrl: './ui-main-menu.component.html',
  styleUrls: ['./ui-main-menu.component.scss'],
})
export class UiMainMenuComponent implements OnInit {
  @Input() userRegistration = null;

  account: AccountInfo;
  menu: MenuItem[] = [
    {
      label: 'Agendamento de consultas',
      icon: 'calendar-alt',
      link: '/indications',
      submenu: [],
    },
    {
      label: 'Assistência técnica',
      icon: 'wrench',
      link: '/technical-assistance',
      submenu: [],
    },
    {
      label: 'Meus clientes',
      icon: 'users',
      link: '/patients',
      submenu: [],
    },
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.loginService.startIntercom(LocalStorageUtil.getAccountInfo());

    if (this.userRegistration) {
      this.account = this.userRegistration;
      return;
    } else {
      this.account = LocalStorageUtil.getAccountInfo();

      if (this.account.admin)
        this.menu.push({
          label: 'Conta',
          icon: 'credit-card',
          submenu: [
            { label: 'Assinatura', link: '/signature' },
            { label: 'Contratante', link: '/contractor' },
            { label: 'Usuários', link: '/users' },
          ],
        });
    }

    if (this.account.superAdmin) {
      this.menu.push({
        label: 'Administrativo',
        icon: 'user',
        submenu: [
          { label: 'Assumir conta', link: '/take-account' },
          { label: 'Editar indicação', link: '/edit-indication' },
        ]
      });

      /*this.menu.push({
        label: 'Cadastros',
        icon: 'user',
        submenu: [
          { label: 'Especialidades', link: '/specialties' },
          { label: 'Médicos', link: '/doctors' },
        ],
      });*/

      this.menu.push({
        label: 'Relatórios',
        icon: 'file-invoice',
        submenu: [
          {
            label: 'NPS - Indicações por Médico',
            link: '/reports/nps/indications-by-doctor',
          },
          {
            label: 'NPS - Média por Médico',
            link: '/reports/nps/average',
          },
        ],
      });
    }
  }

  onClick(item: { link: string }) {
    if (item.link) {
      this.router.navigate([item.link]);
    }
  }

  logout() {
    this.loginService.stopIntercom();
    this.router.navigate(['/login']);
    LocalStorageUtil.logout();
  }

  firstName(name: string) {
    return name.split(' ').length > 0 ? name.split(' ')[0] : name;
  }

  goToProfile() {
    const token = LocalStorageUtil.getToken();
    this.router.navigate([`/profile/${token}`]);
  }

  resetPass() {
    const dialogRef = this.dialog.open(UiChangePasswordComponent);
    dialogRef.afterClosed().subscribe();
  }
}
