import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { Observable } from 'rxjs';
import { DoctorNPS } from '../interfaces/doctor-nps.interface';
import { CreateDoctorDTO, Doctor, DoctorListParams } from '../modules/admin/dto/doctor.dto';
import { HttpParams } from '@angular/common/http';
import { take } from 'rxjs/operators';

type FindDoctorNpsDTO = {
  indicationId: number;
  doctorId: number;
};

type ApplyDoctorNpsDTO = {
  indicationId: number;
  doctorId: number;
  nps: number;
  comment: string;
};

export type DoctorFilter = {
  name: string;
  cpf: string;
};

@Injectable({
  providedIn: 'root',
})
export class DoctorService {
  constructor(private readonly apiService: ApiServiceService) {}

  applyNps({
    doctorId,
    indicationId,
    ...body
  }: ApplyDoctorNpsDTO): Observable<void> {
    return this.apiService.post(
      `/doctor/${doctorId}/nps/${indicationId}`,
      body
    );
  }

  findNps({ doctorId, indicationId }: FindDoctorNpsDTO): Observable<DoctorNPS> {
    return this.apiService.get(`/doctor/${doctorId}/nps/${indicationId}`);
  }

  findAll(params: DoctorListParams): Observable<{list: Doctor[], total: number}> {
    const queryParams = Object.fromEntries(
      Object.entries(params).map(([key, value]) => [key, String(value)])
    );
    const queryString = new URLSearchParams(queryParams).toString();
    return this.apiService.get(`/doctor?${queryString}`);
  }

  findByCpf(cpf: string): Observable<Doctor> {
    return this.apiService.get(`/doctor/cpf/${cpf}`); 
  }

  save(doctor: CreateDoctorDTO): Observable<Doctor> {
    return this.apiService.post('/doctor', doctor);
  }

  toggleActive(doctorId: number, active: boolean): Observable<void> {
    return this.apiService.put(`/doctor/${doctorId}/toggle-active`, { active });
  }
    
  search(filter: DoctorFilter) {
    const params = new HttpParams().set('filter', JSON.stringify(filter));

    return this.apiService.get('/doctor/combo', params).pipe(take(1));
  }
}
