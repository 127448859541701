import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { HttpParams } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

export type DoctorReportNPSFilter = {
  doctorId: number;
  period: {
    to: string;
    from: string;
  };
};

export type DoctorReportAverageFilter = {
  period: {
    to: string;
    from: string;
  };
};

export type DoctorReportNPS = {
  nps: number;
  comment: string | null;
  createdAt: string;
  doctorName: string;
  userName: string;
  patientName: string;
  indicationId: number;
};

export type DoctorReportAverageNPS = {
  userName: string;
  averageNps: number;
  indicationsAmount: number;
};

@Injectable({
  providedIn: 'root',
})
export class DoctorReportsNpsService {
  constructor(private readonly apiService: ApiServiceService) {}

  findDoctorReportNPS(filter: DoctorReportNPSFilter) {
    const params = new HttpParams().set('filter', JSON.stringify(filter));

    return this.apiService.get('/reports/doctor/nps', params).pipe(take(1));
  }

  findDoctorReportAverageNPS(
    filter: DoctorReportAverageFilter
  ): Observable<DoctorReportAverageNPS[]> {
    const params = new HttpParams().set('filter', JSON.stringify(filter));

    return this.apiService
      .get('/reports/doctor/nps/average', params)
      .pipe(take(1));
  }
}
