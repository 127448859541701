<div>
    <mat-card-header>
      <mat-card-title>Cadastro de Médico</mat-card-title>
    </mat-card-header>

    <br>

    <form [formGroup]="form">
      <div class="row margin-top">
        <div class="row">
          <div class="col-sm-3 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>CPF</mat-label>
              <input matInput formControlName="cpf" [specialCharacters]="['/', '.', '-']" [mask]="'000.000.000-00'"
                (change)="onChangeCPF()" required />
              <mat-error *ngIf="form.get('cpf').hasError('required') && form.get('cpf').touched">
                CPF é obrigatório
              </mat-error>
              <mat-error *ngIf="form.get('cpf').hasError('invalid') && form.get('cpf').touched">
                CPF inválido
              </mat-error>
              <mat-error *ngIf="form.get('cpf').hasError('alreadyExist') && form.get('cpf').touched">
                Médico já cadastrado
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-9 col-lg-9">
            <mat-form-field appearance="outline">
              <mat-label>Nome</mat-label>
              <input matInput formControlName="name" required />
              <mat-error *ngIf="form.get('name').hasError('required') && form.get('name').touched">
                Nome é obrigatório
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" required (change)="onChangeEmail()" />
              <mat-error *ngIf="form.hasError('invalid', ['email'])">
                E-mail inválido
              </mat-error>
              <mat-error *ngIf="form.hasError('required', ['email'])">
                E-mail é obrigatório
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>Escolha uma opção</mat-label>
              <mat-select formControlName="specialtyId" required>
                <mat-option value="" disabled>Selecione uma opção</mat-option>
                <mat-option *ngFor="let item of specialties" [value]="item.id">
                  {{ item.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('specialtyId')?.hasError('required')">
                Por favor, selecione uma opção.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="action-buttons">
        <button mat-button class="btn-cancel" mat-dialog-close>
          <fa-icon icon="times"></fa-icon>
          Cancelar
        </button>

        <button mat-button class="btn-green" [disabled]="loading || form.invalid" (click)="saveDoctor()">
          <mat-spinner [diameter]="20" color="primary" class="branco" *ngIf="loading; else btnSalvar">
          </mat-spinner>
          <ng-template #btnSalvar>
            <fa-icon icon="check"></fa-icon>
            Salvar
          </ng-template>
        </button>
      </div>
    </form>
</div>