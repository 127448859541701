import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Specialty } from '../../dto/specialty.dto';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { CreateDoctorDTO, Doctor } from '../../dto/doctor.dto';
import { AddDoctorModalComponent } from './add-doctor-modal/add-doctor-modal.component';
import { debounceTime, switchMap } from 'rxjs/operators';
import { DoctorService } from 'src/app/services/doctor.service';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.scss']
})
export class DoctorsComponent implements OnInit {
  loading = false;
  doctorsList = new MatTableDataSource<Doctor>([]);
  searchSubject = new Subject();
  searchTerm = '';
  active: boolean | undefined;
  order: 'ASC' | 'DESC' | '' = 'ASC';
  paginatorPage = 1;
  paginatorPageSize = 20;
  paginatorLength = 0;
  filters = [
    { name: 'Todos', value: undefined },
    { name: 'Ativos', value: true },
    { name: 'Inativos', value: false },
  ];
  columns = ['name', 'isActive', 'cpf', 'actions'];
  constructor(
    private dialog: MatDialog,
    private readonly doctorService: DoctorService,
    private readonly loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.searchSubject
      .pipe(
        debounceTime(500),
        switchMap(() => {
          this.loading = true;
          return this.doctorService.findAll({
            page: this.paginatorPage,
            limit: this.paginatorPageSize,
            searchTerm: this.searchTerm,
            active: this.active,
            order: this.order,
          });
        }),
      )
      .subscribe({
        next: (response) => {
          this.loading = false;
          const { list, total } = response;
          this.doctorsList.data = list;
          this.paginatorLength = total;
        },
        error: (error) => {
          this.loading = false;
          console.log(error);
          this.loginService.showMessage('Erro ao buscar especialidades', 3000);
        }
      });

    this.searchSubject.next();
  }

  onSearch() {
    this.paginatorPage = 1;
    this.searchSubject.next();
  }

  onSort(event: Sort) {
    this.paginatorPage = 1;
    this.order = event.direction.toUpperCase() as 'ASC' | 'DESC' | '';
    this.searchSubject.next();
  }

  async onChangePage(event: PageEvent): Promise<void> {
    this.paginatorPage = event?.pageIndex + 1;
    this.searchSubject.next();
  }

  openDoctorsModal(doctor?: Doctor) {
    const dialogRef = this.dialog.open(AddDoctorModalComponent, {
      disableClose: false,
      width: '80%',
      ...(doctor && { data: doctor }),
    });

    dialogRef.afterClosed().subscribe({
      next: (doctor: CreateDoctorDTO) => {
        if (doctor) {
          this.saveDoctor(doctor);
        }
      }
    })
  }

  saveDoctor(doctor: CreateDoctorDTO) {
    this.doctorService.save(doctor).subscribe({
      next: () => {
        this.loginService.showMessage('Médico salvo com sucesso', 3000);
        this.searchSubject.next();
      },
      error: (error) => {
        console.log(error);
        this.loginService.showMessage('Erro ao salvar médico', 3000);
      }
    });
  }

  changeIsActive(doctor: Doctor) {
    this.doctorService.toggleActive(
      doctor.id,
      !doctor.isActive
    ).subscribe({
      next: () => {
        this.loginService.showMessage('Médico atualizado com sucesso', 3000);
        this.searchSubject.next();
      },
      error: (error) => {
        console.log(error);
        this.loginService.showMessage('Erro ao atualizar médico', 3000);
      }
    })
  }
}
