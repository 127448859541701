import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { LoginService } from 'src/app/services/login.service';
import { SpecialtyService } from '../../services/specialty.service';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { AddSpecialtyModalComponent } from './add-specialty-modal/add-specialty-modal.component';
import { Specialty } from '../../dto/specialty.dto';

@Component({
  selector: 'app-specialties',
  templateUrl: './specialties.component.html',
  styleUrls: ['./specialties.component.scss']
})
export class SpecialtiesComponent implements OnInit {
  loading = false;
  specialtyList = new MatTableDataSource<Specialty>([]);
  searchSubject = new Subject();
  searchTerm = '';
  active: boolean | undefined;
  order: 'ASC' | 'DESC' | '' = 'ASC';
  paginatorPage = 1;
  paginatorPageSize = 20;
  paginatorLength = 0;
  filters = [
    { name: 'Todos', value: undefined },
    { name: 'Ativos', value: true },
    { name: 'Inativos', value: false },
  ];
  columns = ['name', 'isActive', 'actions'];

  constructor(
    private dialog: MatDialog,
    private readonly specialtyService: SpecialtyService,
    private readonly loginService: LoginService,
  ) {}

  ngOnInit(): void {
    this.searchSubject
      .pipe(
        debounceTime(500),
        switchMap(() => {
          this.loading = true;
          return this.specialtyService.findAll({
            page: this.paginatorPage,
            limit: this.paginatorPageSize,
            searchTerm: this.searchTerm,
            active: this.active,
            order: this.order,
          });
        }),
      )
      .subscribe({
        next: (response) => {
          this.loading = false;
          const { list, total } = response;
          this.specialtyList.data = list;
          this.paginatorLength = total;
        },
        error: (error) => {
          this.loading = false;
          console.log(error);
          this.loginService.showMessage('Erro ao buscar especialidades', 3000);
        }
      });

    this.searchSubject.next();
  }

  onSearch() {
    this.paginatorPage = 1;
    this.searchSubject.next();
  }

  onSort(event: Sort) {
    this.paginatorPage = 1;
    this.order = event.direction.toUpperCase() as 'ASC' | 'DESC' | '';
    this.searchSubject.next();
  }

  changeIsActive(specialty: Specialty) {
    specialty.isActive = !specialty.isActive;

    this.specialtyService.createOrUpdate(specialty).subscribe({
      next: () => {
        this.loginService.showMessage('Especialidade atualizada com sucesso!', 3000);
        this.searchSubject.next();
      },
      error: (error) => {
        console.log(error);
        this.loginService.showMessage('Erro ao atualizar especialidade', 3000);
      },
    });
  }

  async onChangePage(event: PageEvent): Promise<void> {
    this.paginatorPage = event?.pageIndex + 1;
    this.searchSubject.next();
  }

  openAddSpecialtyModal(specialty?: Specialty) {
    const dialogRef = this.dialog.open(AddSpecialtyModalComponent, {
      disableClose: false,
      width: '500px',
      ...(specialty && { data: specialty }),
    });

    dialogRef.afterClosed().subscribe({
      next: (specialty: Specialty) => {
        if (specialty) {
          this.saveSpecialty(specialty)
        }
      }
    })
  }

  saveSpecialty(specialty: Specialty){
    this.loading = true;
    this.specialtyService.createOrUpdate(specialty).subscribe({
      next: (response) => {
        this.loading = false;
        const msg = `Especialidade ${specialty.id ? specialty.name : response.name} atualizada com sucesso`
        this.loginService.showMessage(msg, 3000);
        this.searchSubject.next();
      },
      error: (error) => {
        this.loading = false;
        console.error(error)
        this.loginService.showMessage('Falha ao cadastrar especialidade', 3000)
      }
    });
  }
}
