<app-ui-main-menu>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Relatório NPS - Indicação por Médicos</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form
        [formGroup]="filterForm"
        (ngSubmit)="onSearch()"
        class="filter-container"
      >
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <app-doctor-select-autocomplete
            (selectedDoctor)="onDoctorChange($event)"
          >
          </app-doctor-select-autocomplete>
        </div>

        <mat-form-field
          appearance="outline"
          class="col-xl-3 col-lg-4 col-md-6 col-sm-12"
        >
          <mat-label>Periodo de avaliação</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              placeholder="Data inicial"
              formControlName="from"
            />
            <input matEndDate placeholder="Data final" formControlName="to" />
          </mat-date-range-input>

          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <div class="actions col-xl-2 col-lg-2 col-md-2 col-sm-12">
          <button
            mat-fab
            color="primary"
            aria-label="Buscar"
            type="submit"
            [disabled]="!filterForm.valid"
          >
            <fa-icon icon="search"></fa-icon>
          </button>
        </div>
      </form>

      <app-loading *ngIf="loadingData"></app-loading>
      <div *ngIf="!loadingData">
        <div *ngIf="dataSource.data.length; then table; else noContent"></div>
      </div>
    </mat-card-content>
  </mat-card>
</app-ui-main-menu>

<ng-template #table>
  <table mat-table [dataSource]="dataSource" class="mat-table">
    <ng-container matColumnDef="doctorName">
      <th mat-header-cell *matHeaderCellDef>Médico</th>
      <td mat-cell *matCellDef="let element" class="doctor-name-cell">
        {{ element.doctorName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="userName">
      <th mat-header-cell *matHeaderCellDef class="use-name-cell">
        Contratante
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.userName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="patientName">
      <th mat-header-cell *matHeaderCellDef class="patient-name-cell">
        Paciente
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.patientName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="indicationId">
      <th mat-header-cell *matHeaderCellDef>Indicação</th>
      <td mat-cell *matCellDef="let element" class="indication-id-cell">
        {{ element.indicationId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="nps">
      <th mat-header-cell *matHeaderCellDef class="nps-cell">Nota</th>
      <td mat-cell *matCellDef="let element">
        {{ element.nps }}
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>Comentário</th>
      <td mat-cell *matCellDef="let element" class="comment-cell">
        {{ element.comment }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Data Avaliação</th>
      <td mat-cell *matCellDef="let element" class="created-at-cell">
        {{ element.createdAt | date }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
  </table>
</ng-template>

<ng-template #noContent>
  <div class="no-content">
    Nenhuma avaliação encontrada para o filtro selecionado
  </div>
</ng-template>
