import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { Doctor } from 'src/app/interfaces/doctor.interface';
import {
  DoctorReportNPS,
  DoctorReportsNpsService,
} from 'src/app/services/doctor-reports-nps.service';

@Component({
  selector: 'app-indications-by-doctor',
  templateUrl: './indications-by-doctor.component.html',
  styleUrls: ['./indications-by-doctor.component.scss'],
})
export class IndicationsByDoctorComponent implements OnInit, OnDestroy {
  filterForm!: FormGroup;
  loadingData: boolean = false;

  dataSource: MatTableDataSource<DoctorReportNPS> = new MatTableDataSource([]);
  tableColumns = [
    'indicationId',
    'userName',
    'patientName',
    'doctorName',
    'nps',
    'comment',
    'createdAt',
  ];

  protected _onDestroy = new Subject<void>();
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly doctorReportsNpsService: DoctorReportsNpsService
  ) {}

  getFormControl(value: string): AbstractControl {
    return this.filterForm.get(value);
  }

  ngOnInit(): void {
    this.initForm();
    this.onFilterStatusChanges();
  }

  onDoctorChange(doctor: Doctor | null) {
    this.filterForm.get('doctorId').setValue(doctor?.id ?? null);
  }

  private initForm() {
    const toDay = new Date();
    const agoWeek = new Date(toDay.getTime() - 7 * 24 * 60 * 60 * 1000);

    this.filterForm = this.formBuilder.group({
      to: [toDay, [Validators.required]],
      from: [agoWeek, [Validators.required]],
      doctorId: [null, []],
    });
  }

  private onFilterStatusChanges() {
    this.filterForm.statusChanges
      .pipe(
        debounceTime(500),
        filter((status) => status === 'VALID'),
        tap(() => {
          this.onSearch();
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe();
  }

  public onSearch() {
    this.loadingData = true;
    const { to, from, doctorId } = this.filterForm.getRawValue();

    const fromDate = new Date(from);
    fromDate.setHours(0, 0, 0, 1);

    const toDate = new Date(to);
    toDate.setHours(23, 59, 59, 999);

    this.doctorReportsNpsService
      .findDoctorReportNPS({
        doctorId,
        period: {
          to: toDate.toISOString(),
          from: fromDate.toISOString(),
        },
      })
      .pipe(
        tap((data) => {
          this.dataSource.data = data;
          this.loadingData = false;
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
