<mat-form-field appearance="outline">
  <mat-label>Médico</mat-label>
  <input matInput [formControl]="filterCtrl" [matAutocomplete]="auto" />
</mat-form-field>

<mat-autocomplete
  #auto
  [displayWith]="displayFn"
  (optionSelected)="onDoctorSelected($event.option.value)"
>
  <mat-option *ngFor="let doctor of filteredDoctors" [value]="doctor">
    {{ doctor.cpf | cpf }} &bull; {{ doctor.name }}
  </mat-option>
</mat-autocomplete>
