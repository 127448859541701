import { Component, Inject, Input, OnInit } from '@angular/core';
import { SpecialtyService } from '../../../services/specialty.service';
import { Specialty, SpecialtyListParams } from '../../../dto/specialty.dto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cpfValidator } from 'src/app/util/cpf-validator';
import { LoginService } from 'src/app/services/login.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { CreateDoctorDTO, Doctor } from '../../../dto/doctor.dto';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cp } from 'fs';

@Component({
  selector: 'app-add-doctor-modal',
  templateUrl: './add-doctor-modal.component.html',
  styleUrls: ['./add-doctor-modal.component.scss']
})
export class AddDoctorModalComponent implements OnInit {
  cpf: string = '';
  name: string = '';
  email: string = '';
  specialtyId: number | null = null;

  loading = false;

  specialtiesListParams: SpecialtyListParams = {
    page: 1,
    limit: 1000,
    searchTerm: '',
    active: true,
    order: 'ASC'
  }

  form!: FormGroup;
  specialties: Specialty[] = [];

  isEdition = false;
  constructor(
    private dialogRef: MatDialogRef<AddDoctorModalComponent>,
    private fb: FormBuilder, 
    private specialtyService: SpecialtyService, 
    private loginService: LoginService,
    private doctorService: DoctorService,
    @Inject(MAT_DIALOG_DATA) public data: Doctor
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      cpf: [this.cpf || '', [Validators.required, cpfValidator()]],
      name: [this.name || '', [Validators.required]],
      email: [this.email || '', [Validators.required, Validators.email]],
      specialtyId: [this.specialtyId || '', [Validators.required]]
    });

    if(this.data) {
      this.isEdition = true;
      this.form.setValue({
        cpf: this.data.cpf,
        name: this.data.name,
        email: this.data.email,
        specialtyId: this.data.specialties[0]?.id || ''
      });
    }

    this.getSpecialties()
  }


  getSpecialties() {
    this.specialtyService.findAll(this.specialtiesListParams).subscribe({
      next: (response) => {
        this.specialties = response.list;
      }
    });
  }

  onChangeEmail() {
    if (!this.loginService.isEmailPatternValid(this.form.controls.email.value)) {
      this.form.controls.email.setErrors({
        invalid: true
      })
    }
  }

  async onChangeCPF() {
    const cpf = this.form.controls.cpf.value;

    if (!cpf) {
      this.form.controls.cpf.setErrors({
        required: true
      })
      return;
    }

    if (!this.loginService.isCpfPatternValid(cpf)) {
      this.form.controls.cpf.setErrors({
        invalid: true
      })
      return;
    }

    const exists = await this.doctorService.findByCpf(cpf).toPromise();

    if (exists) { 
      this.form.controls.cpf.setErrors({
        alreadyExist: true
      })
      return;
    }
  }

  async saveDoctor() {
    if (this.form.invalid) {
      this.loginService.showMessage('Preencha todos os campos corretamente');
      return;
    }
    
    const createDoctor: CreateDoctorDTO = this.form.getRawValue();
    if(this.isEdition) {
      createDoctor.id = this.data.id;
    }
    this.dialogRef.close(createDoctor);
  }
}
