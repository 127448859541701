import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Doctor } from 'src/app/interfaces/doctor.interface';
import { DoctorService } from 'src/app/services/doctor.service';

@Component({
  selector: 'app-doctor-select-autocomplete',
  templateUrl: './doctor-select-autocomplete.component.html',
  styleUrls: ['./doctor-select-autocomplete.component.scss'],
})
export class DoctorSelectAutocompleteComponent implements OnInit, OnDestroy {
  @Output() selectedDoctor = new EventEmitter<Doctor | null>();

  filterCtrl: FormControl = new FormControl('');
  filteredDoctors: Doctor[] = [];

  protected _onDestroy = new Subject<void>();

  constructor(private readonly doctorService: DoctorService) {}

  ngOnInit(): void {
    this.onFilterChange();
  }

  displayFn(doctor: Doctor): string {
    return doctor.name ? doctor.name : '';
  }

  onDoctorSelected(doctor: Doctor | null) {
    if (doctor) {
      this.filteredDoctors = [doctor];
    } else {
      this.filteredDoctors = [];
    }

    this.selectedDoctor.emit(doctor);
  }

  private onFilterChange() {
    this.filterCtrl.valueChanges
      .pipe(
        debounceTime(300),
        tap((value) => {
          !value && this.onDoctorSelected(null);
        }),
        filter((value) => typeof value === 'string'),
        tap((value) => this.onSearchDoctors(value)),
        takeUntil(this._onDestroy)
      )
      .subscribe();
  }

  private onSearchDoctors(value: string) {
    if (!value) {
      return;
    }

    return this.doctorService
      .search({
        cpf: value,
        name: value,
      })
      .pipe(
        tap((doctors) => (this.filteredDoctors = doctors)),
        takeUntil(this._onDestroy)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
