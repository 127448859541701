import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { Specialty, SpecialtyListParams } from '../dto/specialty.dto';

@Injectable({
  providedIn: 'root'
})
export class SpecialtyService {
  constructor(
    private readonly apiService: ApiServiceService
  ) {}

  findAll(params: SpecialtyListParams): Observable<{list: Specialty[], total: number}> {
    const queryParams = Object.fromEntries(
      Object.entries(params).map(([key, value]) => [key, String(value)])
    );
    const queryString = new URLSearchParams(queryParams).toString();
    return this.apiService.get(`/specialty?${queryString}`);
  }

  createOrUpdate(data: Specialty): Observable<Specialty> {
    return this.apiService.post('/specialty', data);
  }
}
