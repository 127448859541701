import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { Specialty } from '../../../dto/specialty.dto';

interface SpecialtyForm {
  id: FormControl<number | null>;
  name: FormControl<string>;
  isActive: FormControl<boolean>;
}

@Component({
  selector: 'app-add-specialty-modal',
  templateUrl: './add-specialty-modal.component.html',
  styleUrls: ['./add-specialty-modal.component.scss']
})
export class AddSpecialtyModalComponent implements OnInit {
  loading = false;
  form: FormGroup<SpecialtyForm> = new FormGroup<SpecialtyForm>({
    id: new FormBuilder().control(null),
    name: new FormBuilder().control('', Validators.required),
    isActive: new FormBuilder().control(true, Validators.required),
  });

  constructor(
    public dialogRef: MatDialogRef<AddSpecialtyModalComponent>,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: Specialty
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.form.patchValue(this.data);
    }
  }

  async saveSpecialty() {
    if (this.form.invalid) {
      this.loginService.showMessage('Preencha todos os campos corretamente');
      return;
    }

    const specialty: Specialty = this.form.getRawValue();
    this.dialogRef.close(specialty);
  }
}
