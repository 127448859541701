import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import {
  DoctorReportAverageNPS,
  DoctorReportsNpsService,
} from 'src/app/services/doctor-reports-nps.service';

@Component({
  selector: 'app-nps-average',
  templateUrl: './nps-average.component.html',
  styleUrls: ['./nps-average.component.scss'],
})
export class NpsAverageComponent implements OnInit, OnDestroy {
  filterForm!: FormGroup;
  loadingData: boolean = false;

  dataSource: MatTableDataSource<DoctorReportAverageNPS> =
    new MatTableDataSource([]);
  tableColumns = ['doctorName', 'averageNps', 'indicationAmount'];

  protected _onDestroy = new Subject<void>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly doctorReportsNpsService: DoctorReportsNpsService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.onFilterStatusChanges();
    this.onSearch();
  }

  onSearch() {
    this.loadingData = true;

    const { to, from } = this.filterForm.getRawValue();

    const fromDate = new Date(from);
    fromDate.setHours(0, 0, 0, 1);

    const toDate = new Date(to);
    toDate.setHours(23, 59, 59, 999);

    this.doctorReportsNpsService
      .findDoctorReportAverageNPS({
        period: {
          to: toDate.toISOString(),
          from: fromDate.toISOString(),
        },
      })
      .pipe(
        tap((data) => {
          this.dataSource.data = data;
          this.loadingData = false;
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe();
  }

  private initForm() {
    const toDay = new Date();
    const agoWeek = new Date(toDay.getTime() - 7 * 24 * 60 * 60 * 1000);

    this.filterForm = this.formBuilder.group({
      to: [toDay, [Validators.required]],
      from: [agoWeek, [Validators.required]],
    });
  }

  private onFilterStatusChanges() {
    this.filterForm.statusChanges
      .pipe(
        debounceTime(500),
        filter((status) => status === 'VALID'),
        tap(() => {
          this.onSearch();
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
