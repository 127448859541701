<div>
  <form [formGroup]="form">
    <div class="row margin-top">
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <mat-form-field appearance="outline">
            <mat-label>Nome da especialidade</mat-label>
            <input matInput formControlName="name" required />
            <mat-error *ngIf="form.get('name').hasError('required') && form.get('name').touched">
              Nome  é obrigatório
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="action-buttons">
      <button mat-button class="btn-cancel" mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
        Cancelar
      </button>

      <button mat-button class="btn-green" (click)="saveSpecialty()" [disabled]="loading">
        <mat-spinner [diameter]="20" color="primary" class="branco" *ngIf="loading; else btnSalvar">
        </mat-spinner>
        <ng-template #btnSalvar>
          <fa-icon icon="check"></fa-icon>
          Salvar
        </ng-template>
      </button>
    </div>
  </form>
</div>
