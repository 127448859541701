<app-ui-main-menu>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Médicos</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content class="action-container">
      <div class="field-container">
        <mat-form-field appearance="outline" [floatLabel]="'always'">
          <mat-label>Buscar</mat-label>
          <input matInput type="text" [(ngModel)]="searchTerm" [ngModelOptions]="{ standalone: true }"
            (input)="onSearch()" placeholder="Buscar Médico por nome" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Filtros</mat-label>
          <mat-select [(ngModel)]="active" (selectionChange)="onSearch()">
            <mat-option *ngFor="let f of filters" [value]="f.value">
              {{ f.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="button-container">
        <button mat-button (click)="openDoctorsModal()" class="actionButton">
          <fa-icon icon="plus"></fa-icon>Adicionar
        </button>
      </div>
    </mat-card-content>

    <mat-card-content class="table-container">
      <div *ngIf="loading" class="loader">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <div class="row">
        <div class="col-sm-12" *ngIf="!doctorsList || doctorsList.data?.length === 0">
          Nenhuma especialidade encontrada!
        </div>

        <div class="col-sm-12" *ngIf="doctorsList && doctorsList.data?.length > 0">
          <div class="box-table">
            <table mat-table matSort (matSortChange)="onSort($event)" [dataSource]="doctorsList">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Nome
                </th>
                <td mat-cell *matCellDef="let element" class="cell-left">
                  {{ element.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="cpf">
                <th mat-header-cell *matHeaderCellDef>
                  CPF
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.cpf | cpf }}
                </td>
              </ng-container>

              <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef>
                  Ativo
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.isActive ? 'Sim' : 'Não' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Ações</th>
                <td mat-cell *matCellDef="let element" class="acoes-tab">
                  <button mat-mini-fab type="button" matTooltip="Editar" matTooltipPosition="above"
                    (click)="openDoctorsModal(element)" class="btn-action">
                    <fa-icon icon="pencil-alt"></fa-icon>
                  </button>

                  <button mat-mini-fab type="button" *ngIf="element.isActive" matTooltip="Inativar especialidade"
                    matTooltipPosition="above" (click)="changeIsActive(element)" class="btn-action-inactivate">
                    <fa-icon icon="times"></fa-icon>
                  </button>

                  <button mat-mini-fab type="button" *ngIf="!element.isActive" matTooltip="Reativar especialidade"
                    matTooltipPosition="above" (click)="changeIsActive(element)" class="btn-action-reactivate">
                    <fa-icon icon="undo"></fa-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columns"></tr>
              <tr mat-row *matRowDef="let row; columns: columns; let element"></tr>
            </table>
          </div>
          <mat-paginator [length]="paginatorLength" [pageSize]="paginatorPageSize" [hidePageSize]="true"
            showFirstLastButtons="true" (page)="onChangePage($event)">
          </mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</app-ui-main-menu>