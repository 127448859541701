import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { IndicationsComponent } from './pages/indications/indications.component';
import { PatientsComponent } from './pages/patients/patients.component';
import { SignatureComponent } from './pages/signature/signature.component';
import { ContractorComponent } from './pages/contractor/contractor.component';
import { UsersComponent } from './pages/users/users.component';
import { PatientRegisterComponent } from './pages/patients/patient-register/patient-register.component';
import { AdminGuard } from './shared/guards/admin.guard';
import { IndicationComponent } from './pages/indications/indication/indication.component';
import { InvitationComponent } from './pages/invitation/invitation.component';
import { CompleteRegistrationComponent } from './pages/complete-registration/complete-registration.component';
import { LoggedInGuard } from './shared/guards/loggedin.guard';
import { SuperAdminGuard } from './shared/guards/superAdmin.guard';
import { TakeAccountComponent } from './modules/admin/pages/take-account/take-account.component';
import { EditIndicationComponent } from './modules/admin/pages/edit-indication/edit-indication.component';
import { TechnicalAssistanceComponent } from './modules/technical-assistance/pages/technical-assistance/technical-assistance.component';
import { IndicationsByDoctorComponent } from './pages/reports/nps/indications-by-doctor/indications-by-doctor.component';
import { SpecialtiesComponent } from './modules/admin/pages/specialties/specialties.component';
import { DoctorsComponent } from './modules/admin/pages/doctors/doctors.component';
import { NpsAverageComponent } from './pages/reports/nps/nps-average/nps-average.component';

//TODO: Impl. Lazy loading modules
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'login/:token', component: LoginComponent },
  { path: 'invitation/:token', component: InvitationComponent },
  {
    path: 'complete-registration/:token',
    component: CompleteRegistrationComponent,
  },
  { path: 'profile/:token', component: CompleteRegistrationComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'register/plus-plan', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:token', component: ForgotPasswordComponent },
  {
    path: 'indications',
    component: IndicationsComponent,
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard],
  },
  {
    path: 'indications-scheduling/:patient',
    component: IndicationsComponent,
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard],
  },
  {
    path: 'indication/:id',
    component: IndicationComponent,
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard],
  },
  {
    path: 'indication/:id/:patient',
    component: IndicationComponent,
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard],
  },
  {
    path: 'technical-assistance',
    component: TechnicalAssistanceComponent,
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard],
  },
  {
    path: 'patients',
    component: PatientsComponent,
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard],
  },
  {
    path: 'patients/register/new',
    component: PatientRegisterComponent,
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard],
  },
  {
    path: 'patients/register/:id',
    component: PatientRegisterComponent,
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard],
  },
  {
    path: 'signature',
    component: SignatureComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'contractor',
    component: ContractorComponent,
    canActivate: [AdminGuard],
  },
  { path: 'users', component: UsersComponent, canActivate: [AdminGuard] },
  { path: 'take-account', component: TakeAccountComponent, canActivate: [SuperAdminGuard] },
  { path: 'edit-indication', component: EditIndicationComponent, canActivate: [SuperAdminGuard] },
  { path: 'specialties', component: SpecialtiesComponent, canActivate: [SuperAdminGuard] },
  { path: 'doctors', component: DoctorsComponent, canActivate: [SuperAdminGuard] },
  {
    path: 'reports/nps/indications-by-doctor',
    component: IndicationsByDoctorComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'reports/nps/average',
    component: NpsAverageComponent,
    canActivate: [AdminGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
