<app-ui-main-menu>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Relatório NPS - Média de avaliações</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form
        [formGroup]="filterForm"
        (ngSubmit)="onSearch()"
        class="filter-container"
      >
        <mat-form-field
          appearance="outline"
          class="col-xl-3 col-lg-4 col-md-6 col-sm-12"
        >
          <mat-label>Periodo de avaliação</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              placeholder="Data inicial"
              formControlName="from"
            />
            <input matEndDate placeholder="Data final" formControlName="to" />
          </mat-date-range-input>

          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <div class="actions col-xl-2 col-lg-2 col-md-2 col-sm-12">
          <button
            mat-fab
            color="primary"
            aria-label="Buscar"
            type="submit"
            [disabled]="!filterForm.valid"
          >
            <fa-icon icon="search"></fa-icon>
          </button>
        </div>
      </form>

      <app-loading *ngIf="loadingData"></app-loading>
      <div *ngIf="!loadingData">
        <div *ngIf="dataSource.data.length; then table; else noContent"></div>
      </div>
    </mat-card-content>
  </mat-card>
</app-ui-main-menu>

<ng-template #table>
  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-table col-xl-12 col-lg-12 col-md-12 col-sm-12"
  >
    <ng-container matColumnDef="doctorName">
      <th mat-header-cell *matHeaderCellDef>Médico</th>
      <td mat-cell *matCellDef="let element" class="doctor-name-cell">
        {{ element.doctorName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="averageNps">
      <th mat-header-cell *matHeaderCellDef>Média NPS</th>
      <td mat-cell *matCellDef="let element" class="average-nps-cell">
        {{ element.averageNps }}
      </td>
    </ng-container>

    <ng-container matColumnDef="indicationAmount">
      <th mat-header-cell *matHeaderCellDef class="indication-amount-cell">
        Indicações
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.indicationAmount }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
  </table>
</ng-template>

<ng-template #noContent>
  <div class="no-content col-xl-12 col-lg-12 col-md-12 col-sm-12">
    Nenhuma avaliação encontrada para o filtro selecionado
  </div>
</ng-template>
