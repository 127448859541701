export const environment = {
  production: true,
  api: 'https://api-production.previdas.com.br/v1',
  appVersion: require('../../package.json').version + '-dev',
  sentry: null,
  iuguId: 'B15FC93661374C09884C66CDBEADC18A',
  intercom: 'mcudk4rm',
  communicareApiAgendaOnline: null,
  communicareAccountId: null
};
